import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { connect } from 'react-redux';
import { selectQuestion } from '../state/actions';

import '../components/layout.css';
import '../styles/questions.css';
import '../styles/global.css';

const Three = (props) => {
  const selectOption = (option) => {
    let array = props.questions;

    array.push(option);
    props.selectQuestion(array);
  }

  return (
    <div id="containerQuestions">
      <h1 className="number">3</h1>
      <div class="content">
        <span className="question-text">
          Você conhece a nova geração de consumidores?
        </span>
        <div className="body-buttons">
          <div className="item-button-left">
            <AniLink paintDrip to="/four" replace duration={0.4} direction="left" hex="#000">
              <button className="btn-body btn-dark btn-upper" onClick={() => selectOption(false)}>
                Não
              </button>
            </AniLink>
          </div>
          <div className="item-button-right">
            <AniLink paintDrip to="/four" replace duration={0.4} direction="left" hex="#3ED206">
              <button className="btn-body btn-primary btn-upper" onClick={() => selectOption(true)}>
                Sim
              </button>
            </AniLink>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  questions: state.response
});

const mapStateToDispatch = {
  selectQuestion
}

export default connect(
  mapStateToProps,
  mapStateToDispatch
)(Three);
